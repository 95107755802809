function Footer()
{
    return (
        <footer className="footer text-muted fixed-bottom bg-light opacity-50">
            <div className="container text-center text-secondary">
            </div>
        </footer>
    );
}

export default Footer;