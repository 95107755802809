function Contact() 
{
    return (
        <div className="border shadow rounded-xl mt-4">
            <div className="bg-dark rounded-top-xl">
                <h2 className="p-3 text-white">Contact me</h2>
            </div>
            {/* FIXME: change method from POST */ }
            <form className="form p-4" method="GET" action="/contact">
                <div className="form-group py-1 pb-2 col-12">
                    <label className="form-label p-0" for="name">Name:</label>
                    <input className="form-control" id="name" name="name" type="text" placeholder="name" minlength="2" maxlength="80" required></input>
                </div>
                <div className="form-group py-1 pb-2 col-12">
                    <label className="form-label p-0" for="email">Email:</label>
                    <input className="form-control" id="email" name="email" type="email" placeholder="email" minlength="6" maxlength="80" required></input>
                </div>
                <div className="form-group py-1 pb-2 col-12">
                    <label className="form-label p-0" htmlFor="phone">Phone:</label>
                    <input className="form-control" id="phone" name="phone" type="tel" placeholder="(xxx) xxx-xxxx" pattern="[(]?[0-9]{3}[)]?[\s-]*[0-9]{3}[\s-]*[0-9]{4}" required></input>
                </div>
                <div className="form-group py-1 pb-2 col-12">
                    <label className="form-label p-0" for="represent-company">Do you represent a company?</label>
                    <select className="form-control" name="represent-company">
                        <option selected>No</option>
                        <option>Yes</option>
                    </select>
                </div>
                <div className="form-group py-1 pb-2 col-12" hidden>
                    <label className="form-label p-0" for="company">Company:</label>
                    <input className="form-control" id="company" name="company" type="text" placeholder="company" minlength="2" maxlength="80"></input>
                </div>
                <div className="form-group py-1 pb-2 col-12">
                    <label className="form-label p-0" for="subject">Reason for contact:</label>
                    <textarea className="form-control" id="subject" name="subject" type="text" placeholder="..." minlength="10" maxlength="300" required></textarea>
                </div>
                <div className="row gap-1 mt-4 mx-2 justify-content-center">
                    <button className="btn btn-outline-danger col" type="reset">
                        Reset <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button className="btn btn-primary col" type="submit">
                        Submit <i className="bi bi-plus-circle"></i>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Contact;