function Login() 
{
    return (
        <div className="d-flex justify-content-center align-items-center h-75">
            <div className="border shadow bg-light rounded-15 mt-4 col-lg-3 col-md-4 col-sm-6">
                <form className="form p-4" method="POST" action="/auth/login">
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="email">Email:</label>
                        <input className="form-control" id="email" name="email" type="email" placeholder="email" minLength="6" maxLength="80" required></input>
                    </div>
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="password">Password:</label>
                        <input className="form-control" id="password" name="password" type="password" placeholder="password" minLength="8" maxLength="80" required></input>
                    </div>
                    <br />
                    <div className="form-group py-1 pb-2 col-12">
                        <input className="mx-2" id="password" name="remember-me" type="checkbox"></input>
                        <label className="form-label p-0" htmlFor="remember-me">Remember me</label>
                    </div>
                    {/* <br /> */ }
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary col" type="submit">
                            Sign In
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;