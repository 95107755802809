import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import About from './components/about/About';
import Layout from './components/layout/Layout';
import reportWebVitals from './js/reportWebVitals';


function Loginx()
{
    // return <p>You made it</p>;
    // const [ email, setEmail ] = useState( '' );
    // const [ password, setPassword ] = useState( '' );

    // const handleSubmit = async ( event ) =>
    // {
    //     event.preventDefault();
    //     // Send login request to API
    //     const response = await fetch( 'https://api.mywebsite.com/login', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify( { email, password } ),
    //     } );

    //     const data = await response.json();

    //     if ( data.token )
    //     {
    //         // Save token to localStorage or a cookie
    //         localStorage.setItem( 'authToken', data.token );
    //         // Redirect to a secure page or update the state
    //         console.log( 'Login successful' );
    //     } else
    //     {
    //         console.log( 'Login failed' );
    //     }
    // };
}


const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
    <React.StrictMode>
        <Layout>
        </Layout>
    </React.StrictMode>
);


// // import db from "./js/db.js";
// const db = require( "./js/db.js" );
// const express = require( "express" );
// const app = express();
// app.post( "/login", ( req, res ) =>
// {
//     db.login();
// } );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
